import PropTypes from 'prop-types'
import React from 'react'
import scraper from '../images/web-scraper.png'
import pic02 from '../images/pic02.jpg'
import ment from '../images/ment.png'
import buyblack from '../images/buy-black.png'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
          {/* <span className="image main">
            <img src={pic01} alt="" />
          </span> */}
          <p>
            By day a DevOps Engineer, by night a civic hacker with a mind 
            for change. I create web applications to support organizations 
            trying to make an impact, including Code for America and its
            local brigade Code for Atlanta. At my job, I regularly 
            participate in competitions to pitch effective solutions to 
            industry challenges to execs.
          </p>
          <p>
            On the side, I'm an alumni board member for the coding bootcamp
            that started my tech journey; I cohost a podcast on breaking into
            the tech field and mastering the job hunt; and I coordinate study
            groups for coding newbies.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <span className="image main">
            <a href="https://www.buyblack.guru/">
            <img src={buyblack} alt="Asset map of black owned restaurants" />
            </a>
          </span>
          <p>
            An asset map that uses the Mapbox API to help users find black-owned restaurants in Atlanta
            and filter them by price, cuisine and location. Allows users to submit new restaurants as well.
          </p>
          <a href="https://github.com/erinborders/atlanta-black-businesses">
            <button>Github</button>
          </a>
          

          <hr></hr>

          <span className="image main">
            <a href="https://github.com/erinborders/scrapers">
              <img src={scraper} alt="" />
            </a>
          </span>
          <p>
            An extension of the black-owned restaurants asset map, this web scraper uses Python and the Scraper 
            framework to find the name, address, and contact information of black-owned businesses in Atlanta,
            then compiles it into a JSON file.
          </p>
          <a href="https://github.com/erinborders/scrapers">
            <button>Github</button>
          </a>

          <hr></hr>

          <span className="image main">
            <a href="https://ment.herokuapp.com">
            <img src={ment} alt="Web app with career information" />
            </a>
          </span>
          <p>
            A Python, Django, and React web application that uses the Career One Stop API 
            to help users determine a career path by listing the skills and education required 
            for each career. Also provides local job centers and potential mentors to reach out 
            to or learn from.
          </p>
          
          <a href="https://github.com/erinborders/ment">
            <button>Github</button>
          </a>

          
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Resume</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <h3>Skills</h3>
          <ul className="icons">
            <lil>Docker, Kubernetes, Golang, Azure ServiceBus, Azure DevOps, Ansible, Jenkins, SCM, bash scripting, AWS Cloud Practitioner Certified, AWS Solutions Architect Certified, Python,  New Relic, Prisma</lil>
              {/* <a href="#" className="icon fa-twitter"></a> */}
            
          </ul>
          <hr></hr>

          <h3>Experience</h3>

          <h4>LEAP Apprentice - Software Engineer</h4>
          <h5>Microsoft</h5>
          <h6>Feb 2022 - Present, Atlanta</h6>
          <ul>
            <li>Improved new product centered on helping developers deploy containerized applications by adding Github OIDC setup and increased test coverage.</li>
            <lil>Facilitated large scale migration of 20+ operations from SQL queue to Azure ServiceBus and increased unit testing in this area to over 85%.</lil>
            <li>Managed deployment of 3  components to end to end testing environment for more robust testing.</li>
            <li>Spearheaded design and implementation for large scale dead code removal following the ServiceBus migration. </li>
            <li>Developed 2 design documents for a zero downtime database cross-region migration and Service Bus deadletter queue monitor.</li>
            <li>Increased module  security by creating permissions checking role interceptor.</li>
          </ul>

          <h4>Application Development Associate</h4>
          <h5>Accenture</h5>
          <h6>Feb 2020 - Feb 2022, Atlanta</h6>
          <ul>
            <li>Utilized Docker and Kubernetes to spearhead migration of 8 microservices to private cloud platform and develop cloud native solutions.</li>
            <li>Reduced Docker image security issues by an average of 90% across 24 cloud applications by implementing automated Prisma vulnerability scanning.</li>
            <li>Conceptualized integration of 2 new softwares into current workflow, as well as an extended application of Kubernetes templating.</li>
            <li>Developed a Python script that reduces Jenkins pipeline breaks and increases pipeline stability across 40 pipelines.</li>
            <li>1 of 5 finalists in 60+ team pitch case competition for developing and pitching a product proposal solving a critical industry problem in the wake of the pandemic to Accenture leadership. </li>
          </ul>

          <h4>Freelance/Full Stack Engineer</h4>
          <h6>Aug 2019 - Feb 2020, Atlanta</h6>
          <ul>
            <li>Managed the development from design to deployment of The Pop Up Guide, a full-stack MERN application that provides pop up event information to end users.</li>
            <li>Provided one-on-one mentoring services to emerging full-stack developers, especially General Assembly alumni.</li>
            <li>Presented in, and moderated, panel discussions illuminating the software engineering and DevOps industries, as well as the strengths and areas for improvement of coding bootcamp curriculum</li>
          </ul>
          
          <h4>Software Engineering Fellow</h4>
          <h6>May 2019 - Aug 2019, Atlanta</h6>
          <ul>
            <li>Over 400 hours building applications that met user needs, modeling data, developing wireframes, and working collaboratively through version control.</li>
            <li>Built applications from the ground up and leveraged JavaScript libraries like React to build a dynamic front-end.</li>
            <li>Nominated Student of the Week for stellar performance.</li>
          </ul>

          <hr></hr>

          <h4>Education</h4>
          <ul>
            <li>Washington University in St. Louis, Aug 2013 - May 2017 </li>
          </ul>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="https://getform.io/f/07d8079c-5575-4711-8389-f3dd4a552850">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/erinwithanAyy"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/erin-borders/" className="icon fa-linkedin">
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/erinborders/" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/erinborders"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
